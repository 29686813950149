<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $objectDenomination(vehicle) }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SKU") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.sku }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.VIN") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.vin }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MAKE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.make }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MODEL") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.model }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SERIES") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.series }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MODEL_YEAR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.model_year }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.STYLE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.style }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ENGINE_DESCRIPTION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.engine_description }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.FUEL_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{
            vehicle.fuel_type
              ? $t(`FUEL_TYPES.${vehicle.fuel_type.toUpperCase()}`)
              : "-"
          }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.TRANSMISSION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.transmission }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.DRIVETRAIN") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.drivetrain }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CLASS_CODE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.class_code }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CLASS_NAME") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.class_name }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ODOMETER") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.odometer }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.REGISTRATION_NUMBER") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.registration_number }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.COLOR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.color }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.REBUILT_STATUS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.rebuilt_status ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ACCIDENT_INVOLVED") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.accident_involved ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.REPAIR_DETAILS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.repair_details }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.REPAIR_COSTS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.repair_costs }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.PANELS_REPAIRED") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.panels_repaired ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.PANEL_DETAILS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.panel_details }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ODOMETER_MILEAGE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.odometer_mileage }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ODOMETER_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.odometer_type }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ODOMETER_PROBLEM") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.odometer_problem ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.OUTSIDE_COLOR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.outside_color }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.INSIDE_COLOR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.inside_color }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MECHANICAL_PROBLEM") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.mechanical_problem ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MECHANICAL_PROBLEM_DETAILS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.mechanical_problem_details }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.LEDS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.leds ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.PRIOR_DAMAGES") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.prior_damages ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CARFAX") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.carfax ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.INSPECTION_REQUIRED") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.inspection_required ? "Yes" : "No" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MANUFACTURER_WARRANTY_EXPIRATION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.manufacturer_warranty_expiration }}
        </dd>
      </dl>
      <dl class="row">
                <dt class="col-sm-4">{{ $t("VEHICLES.SHELF_INFLATING_BAGS_WORK") }}</dt>
                <dd class="col-sm-8">
                    {{ vehicle.shelf_inflating_bags_work ? $t("COMMON.YES") : $t("COMMON.NO") }}
                </dd>
            </dl>
            <dl class="row">
                <dt class="col-sm-4">{{ $t("VEHICLES.ORIGIN") }}</dt>
                <dd class="col-sm-8">
                    {{ vehicle.origin }}
                </dd>
            </dl>
            <dl class="row" v-if="vehicle.origin_details">
                <dt class="col-sm-4">{{ $t("VEHICLES.ORIGIN_DETAILS") }}</dt>
                <dd class="col-sm-8">
                    {{ vehicle.origin_details }}
                </dd>
            </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="vehicle.allowedLocations" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="vehicle.organization" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.created_at">
          {{ $timeZoneDateFormat(vehicle.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.updated_at">
          {{ $timeZoneDateFormat(vehicle.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "vehicle-view-global",

  components: {},

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
