<template>
  <form class="inspection-form" ref="profile_form" @submit.prevent="handleSubmit">
    <div class="inspection-form-title">
      <h3>{{ $t("VEHICLES.VEHICLE_IDENTIFICATION") }}</h3>
    </div>

    <div class="inspection-form-divider"></div>
    <div
      class="inspection-form-group width-1"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !hideOrganization
      "
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="vehicle.organization?.id"
          :filterable="true"
          :showAll="false"
          :disabled="disable"
          @organizationChanged="
            (organizationId) => {
              reset();
              vehicle.organization.id = organizationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div
      class="inspection-form-group width-1"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) && !hideOrganization"
    >
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
      >
        <locations-selector
          :locations="vehicle.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="vehicle.organization?.id"
          :disabled="disable"
          @locationsChanged="
            (locations) => {
              vehicle.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="inspection-form-group width-1 has-button">
      <base-input
        :label="`${$t('VEHICLES.SEARCH_BY_VIN')}`"
        :placeholder="$t('VEHICLES.SEARCH_BY_VIN')"
      >
        <vehicle-api-by-vin-selector
          :filterOrganization="vehicle.organization?.id"
          :disabled="disable"
          :vin="vehicle.vin"
          @vehicleSelected="
            (vehicleData) => {
              vehicle.id = `${vehicleData.id}`;
              vehicle.vin = vehicleData.vin ?? vehicleData.requested_vin;
              vehicle.make = vehicleData.make;
              vehicle.model = vehicleData.model;
              vehicle.series = vehicleData.series;
              vehicle.model_year = vehicleData.model_year;
              vehicle.style = vehicleData.style;
              vehicle.engine_description = vehicleData.engine_description;
              vehicle.fuel_type = vehicleData.fuel_type;
              vehicle.transmission = vehicleData.transmission;
              vehicle.drivetrain = vehicleData.drivetrain;
              vehicle.class_code = vehicleData.class_code;
              vehicle.class_name = vehicleData.class_name;
              vehicle.gallery = vehicleData.gallery || [];
              vehicle.registration_number = vehicleData.registration_number;
              vehicle.odometer = vehicleData.odometer;
              vehicle.color = vehicleData.color;
              vehicle.sku = vehicleData.sku;
              vehicle.fuel_type = vehicleData.fuel_type;
              onFormChanged();
            }
          "
          clearable
        />
      </base-input>
      <base-button class="expand" icon size="sm">
        <i class="far fa-expand"></i>
      </base-button>
    </div>

    <div class="inspection-form-group width-1 gallery">
      <gallery-selector
        :label="$t('COMMON.PICTURES')"
        :defaultGallery="vehicle.gallery"
        ressource_name="vehicles"
        :ressource_id="vehicle.id ? vehicle.id : 0"
        field="gallery"
        :disabled="disable"
        @galleryChanged="
          (gallery_urls) => {
            vehicle.gallery = gallery_urls;
            onFormChanged();
          }
        "
      />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.SKU')} `"
        :placeholder="$t('VEHICLES.SKU')"
        v-model="vehicle.sku"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.sku" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.VIN')} `"
        :placeholder="$t('VEHICLES.VIN')"
        v-model="vehicle.vin"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.vin" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.REGISTRATION_NUMBER')} `"
        :placeholder="$t('VEHICLES.REGISTRATION_NUMBER')"
        v-model="vehicle.registration_number"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.registration_number" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.MODEL_YEAR')} (*)`"
        :placeholder="$t('VEHICLES.MODEL_YEAR')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <year-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.model_year"
          @yearChanged="
            (year) => {
              vehicle.model_year = year;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.model_year" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.MAKE')} (*)`"
        :placeholder="$t('VEHICLES.MAKE')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <make-selector
          :year="vehicle.model_year"
          :disabled="disable"
          :filterable="true"
          :placeholder="$t('VEHICLES.MAKE')"
          :value="vehicle.make"
          @makeChanged="
            (make) => {
              vehicle.make = make;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.make" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.MODEL')} (*)`"
        :placeholder="$t('VEHICLES.MODEL')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <model-selector
          :year="vehicle.model_year"
          :disabled="disable"
          :filterable="true"
          :make="vehicle.make"
          :value="vehicle.model"
          @modelChanged="
            (model) => {
              vehicle.model = model;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.model" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.SERIES')}`"
        :placeholder="$t('VEHICLES.SERIES')"
        :disabled="disable"
      >
        <serie-selector
          :year="vehicle.model_year"
          :make="vehicle.make"
          :disabled="disable"
          :model="vehicle.model"
          :filterable="true"
          :value="vehicle.series"
          @serieChanged="
            (serie) => {
              vehicle.series = serie;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.series" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.STYLE')}`"
        :placeholder="$t('VEHICLES.STYLE')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <style-selector
          :year="vehicle.model_year"
          :make="vehicle.make"
          :model="vehicle.model"
          :serie="vehicle.serie"
          :disabled="disable"
          :filterable="true"
          :value="vehicle.style"
          @styleChanged="
            (style) => {
              vehicle.style = style;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.style" />
    </div>

    <!-- <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.CLASS_CODE')}`"
        :placeholder="$t('VEHICLES.CLASS_CODE')"
        v-model="vehicle.class_code"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.class_code" />
    </div> -->

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.CLASS_NAME')}`"
        :placeholder="$t('VEHICLES.CLASS_NAME')"
        v-model="vehicle.class_name"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.class_name" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.COLOR')} `"
        :placeholder="$t('VEHICLES.COLOR')"
        v-model="vehicle.color"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.color" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.ENGINE_DESCRIPTION')} `"
        :placeholder="$t('VEHICLES.ENGINE_DESCRIPTION')"
        v-model="vehicle.engine_description"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.engine_description" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.FUEL_TYPE')} `"
        :placeholder="$t('VEHICLES.FUEL_TYPE')"
        v-model="vehicle.fuel_type"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <fuel-type-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.fuel_type"
          :options="fuelTypes ?? []"
          @fuelTypeChanged="
            (fuelType) => {
              vehicle.fuel_type = fuelType;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.fuel_type" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.TRANSMISSION')} `"
        :placeholder="$t('VEHICLES.TRANSMISSION')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <transmission-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.transmission"
          @transmissionChanged="
            (transmission) => {
              vehicle.transmission = transmission;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.transmission" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.DRIVETRAIN')} `"
        :placeholder="$t('VEHICLES.DRIVETRAIN')"
        v-model="vehicle.drivetrain"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.drivetrain" />
    </div>

<!--     <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.ODOMETER')} `"
        :placeholder="$t('VEHICLES.ODOMETER')"
        v-model="vehicle.odometer"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.odometer" />
    </div> -->

    <!-- New fields -->
    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.TRIM')}`"
        :placeholder="$t('VEHICLES.TRIM')"
        v-model="vehicle.trim"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.trim" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.ODOMETER_MILEAGE')}`"
        :placeholder="$t('VEHICLES.ODOMETER_MILEAGE')"
        v-model="vehicle.odometer_mileage"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.odometer_mileage" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.ODOMETER_TYPE')}`"
        :placeholder="$t('VEHICLES.ODOMETER_TYPE')"
        v-model="vehicle.odometer_type"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <select v-model="vehicle.odometer_type" :disabled="disable">
          <option value="kms">KMS</option>
          <option value="miles">Miles</option>
        </select>
      </base-input>
      <validation-error :errors="apiValidationErrors.odometer_type" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.ODOMETER_PROBLEM')}`"
        type="checkbox"
        v-model="vehicle.odometer_problem"
        :disabled="disable"
        @change="handleCheckboxChange(vehicle.odometer_problem,'odometer_problem')"
      />
      <validation-error :errors="apiValidationErrors.odometer_problem" />
    </div>
    <label class="form-control-label"> {{ $t('VEHICLES.INTERIEUR') }} </label>

    <div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.LEDS')}`"
    :placeholder="$t('VEHICLES.LEDS')"
    type="checkbox"
    v-model="vehicle.leds"
    :disabled="disable"
    @change="handleCheckboxChange(vehicle.leds,'leds')"
  />
  <validation-error :errors="apiValidationErrors.leds" />
</div>
    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.OUTSIDE_COLOR')}`"
        :placeholder="$t('VEHICLES.OUTSIDE_COLOR')"
        v-model="vehicle.outside_color"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.outside_color" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.INSIDE_COLOR')}`"
        :placeholder="$t('VEHICLES.INSIDE_COLOR')"
        v-model="vehicle.inside_color"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.inside_color" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.MANUFACTURER_WARRANTY_EXPIRATION')}`"
        :placeholder="$t('VEHICLES.MANUFACTURER_WARRANTY_EXPIRATION')"
        v-model="vehicle.manufacturer_warranty_expiration"
        type="date"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.manufacturer_warranty_expiration" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.MECHANICAL_PROBLEM')}`"
        type="checkbox"
        v-model="vehicle.mechanical_problem"
        :disabled="disable"
        @change="handleCheckboxChange(vehicle.mechanical_problem,'mechanical_problem')"
      />
      <validation-error :errors="apiValidationErrors.mechanical_problem" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.MECHANICAL_PROBLEM_DETAILS')}`"
        :placeholder="$t('VEHICLES.MECHANICAL_PROBLEM_DETAILS')"
        v-model="vehicle.mechanical_problem_details"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.mechanical_problem_details" />
    </div>

    <div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.ANTECEDENT_VEHICLE')}`"
    :placeholder="$t('VEHICLES.ANTECEDENT_VEHICLE')"
    v-model="vehicle.antecedent_vehicle"
    :disabled="disable"
    @change="onFormChanged()"
  />
  <validation-error :errors="apiValidationErrors.antecedent_vehicle" />
</div>



<div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.REBUILT_STATUS')}`"
    :placeholder="$t('VEHICLES.REBUILT_STATUS')"
    type="checkbox"
    v-model="vehicle.rebuilt_status"
    :disabled="disable"
    @change="handleCheckboxChange(vehicle.rebuilt_status,'rebuilt_status')"
  />
  <validation-error :errors="apiValidationErrors.rebuilt_status" />
</div>

<div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.INSPECTION_REQUIRED')}`"
    :placeholder="$t('VEHICLES.INSPECTION_REQUIRED')"
    type="checkbox"
    v-model="vehicle.inspection_required"
    :disabled="disable"
    @change="handleCheckboxChange(vehicle.inspection_required,'inspection_required')"
  />
  <validation-error :errors="apiValidationErrors.inspection_required" />
</div>

<div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.CARFAX')}`"
    :placeholder="$t('VEHICLES.CARFAX')"
    type="checkbox"
    v-model="vehicle.carfax"
    @change="handleCheckboxChange(vehicle.carfax,'carfax')"
    />
<!--     :disabled="disable" -->
  <validation-error :errors="apiValidationErrors.carfax" />
</div>

<div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.ACCIDENT_INVOLVED')}`"
    :placeholder="$t('VEHICLES.ACCIDENT_INVOLVED')"
    type="checkbox"
    v-model="vehicle.accident_involved"
    @change="handleCheckboxChange(vehicle.accident_involved,'accident_involved')"
    />
    <!--   :disabled="disable" -->
  <validation-error :errors="apiValidationErrors.accident_involved" />
</div>

<div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.REPAIR_DETAILS')}`"
    :placeholder="$t('VEHICLES.REPAIR_DETAILS')"
    v-model="vehicle.repair_details"
    :disabled="disable"
    @change="onFormChanged()"
  />
  <validation-error :errors="apiValidationErrors.repair_details" />
</div>


<div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.PANELS_REPAIRED')}`"
    :placeholder="$t('VEHICLES.PANELS_REPAIRED')"
    type="checkbox"
    v-model="vehicle.panels_repaired"
    :disabled="disable"
    @change="handleCheckboxChange(vehicle.panels_repaired,'panels_repaired')"
  />
  <validation-error :errors="apiValidationErrors.panels_repaired" />
</div>

<div class="inspection-form-group width-1_4">
  <base-input
    :label="`${$t('VEHICLES.PANEL_DETAILS')}`"
    :placeholder="$t('VEHICLES.PANEL_DETAILS')"
    v-model="vehicle.panel_details"
    :disabled="disable"
    @change="onFormChanged()"
  />
  <validation-error :errors="apiValidationErrors.panel_details" />
</div>
<div class="inspection-form-group width-1">
      <base-input
        :label="`${$t('VEHICLES.ORIGIN')} (*)`"
        :placeholder="$t('VEHICLES.ORIGIN')"
        v-model="vehicle.origin"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.origin" />
    </div>
    <div class="inspection-form-group width-1">
      <base-input
        :label="`${$t('VEHICLES.SHELF_INFLATING_BAGS_WORK')} (*)`"
        :placeholder="$t('VEHICLES.SHELF_INFLATING_BAGS_WORK')"
        v-model="vehicle.shelf_inflating_bags_work"
        :disabled="disable"
        type="checkbox"
        @change="handleCheckboxChange(vehicle.shelf_inflating_bags_work,'shelf_inflating_bags_work')"
      />
      <validation-error :errors="apiValidationErrors.shelf_inflating_bags_work" />
    </div>
    <div class="inspection-form-group width-1">
      <base-input
        :label="`${$t('VEHICLES.ORIGIN_DETAILS')} (*)`"
        :placeholder="$t('VEHICLES.ORIGIN_DETAILS')"
        v-model="vehicle.origin_details"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.origin_details" />
    </div>
    
    <slot name="submit-button" v-bind:loading="loading" v-bind:disable="disable || !vehicle.vin">
      <div class="inspection-form-group width-1 next-button">
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          :disabled="loading || disable || !vehicle.vin"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          {{ saveButtonText }}
        </base-button>
      </div>
    </slot>
  </form>
</template>
<script>
import _, { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import VehicleApiByVinSelector from "@/components/VehicleApiByVinSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import StyleSelector from "@/components/StyleSelector.vue";
import FuelTypeSelector from "@/components/FuelTypeSelector.vue";
import TransmissionSelector from "@/components/TransmissionSelector.vue";
export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    VehicleApiByVinSelector,
    GallerySelector,
    FuelTypeSelector,
    MakeSelector,
    YearSelector,
    ModelSelector,
    SerieSelector,
    StyleSelector,
    TransmissionSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: { type: Object | Array, default: null },
    loading: { type: Boolean, default: false },
    hideOrganization: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
    saveText: { type: String, default: null },
    fuelTypes: { type: Array | undefined | null, default: null },
  },

  data() {
    let vehicleData = cloneDeep(this.vehicleData);
    vehicleData = this.$fillUserOrganizationData(vehicleData);
    return {
      vehicle: vehicleData,
      permissions: [],
      permissionsModel: {},
      models: models,
      console: console,
      vehicleBackup: vehicleData,
    };
  },

  computed: {
    saveButtonText() {
      if (this.saveText) {
        return this.saveText;
      }
      /*  if (this.$idExist(this.vehicle?.id)) { */
      if (this.vehicle && this.vehicle.id) {
        return this.$t("VEHICLES.EDIT_VEHICLE");
      } else {
        return this.$t("VEHICLES.ADD_VEHICLE");
      }
    },
  },

  created() { },

  methods: {
    async handleSubmit() {
      let vehicleData = cloneDeep(this.vehicle);
      vehicleData = this.$fillUserOrganizationData(vehicleData);

     console.log(vehicleData,"===================vehicleSubmitted");
      this.$emit("vehicleSubmitted", vehicleData);
    },

    reset() {
      this.vehicle = { ...this.vehicle, ...this.vehicleBackup };
    },

    onFormChanged() {
      this.$emit("formChanged", this.vehicle);
    },


    handleCheckboxChange(value, attrib) {

  const isChecked = value === 'true';

  this.vehicle[attrib] = !isChecked;
  // Log the updated value for debugging
  console.log(attrib, 'attribute');
  console.log("Checkbox changed:", this.vehicle[attrib]);

  // Emit the formChanged event with the updated vehicle data
  this.$emit("formChanged", this.vehicle);
}

  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = {
          ...this.vehicle,
          ...cloneDeep(vehicleData),
        };
      }
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>

