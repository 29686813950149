export default {
  type: "vehicles",
  sku: null,
  vin: null,
  make: null,
  model: null,
  series: null,
  model_year: 0,
  style: null,
  engine_description: null,
  fuel_type: null,
  transmission: null,
  drivetrain: null,
  class_code: null,
  class_name: null,
  gallery: [],
  odometer: null,
  registration_number: null,
  color: null,
  relationshipNames: ["organization", "allowedLocations", "vehicle"],
  organization: {
    type: "organizations",
    id: null,
    email: null,
    address: null,
    phone: null,
    created_at: null,
    updated_at: null
  },
  allowedLocations: [],
  trim: null,
  odometer: null ,
  odometer_mileage: null,
  odometer_type: null,
  odometer_problem: false,
  outside_color: null,
  inside_color: null,
  manufacturer_warranty_expiration: null,
  mechanical_problem: false,
  mechanical_problem_details: null,
  leds: false,
  mechanics_problems: false,
  prior_damages: false,
  carfax: false,
  inspection_required: false,
  rebuilt_status: false,
  accident_involved: false,
  repair_details: null,
  repair_costs: null,
  panels_repaired: false,
  panel_details: null
};
