<template>
  <el-select
    v-model="makeModel"
    @change="makeChanged"
    :placeholder="$t('ESTIMATE_VEHICLES.MAKE')"
    :filterable="filterable"
    :disabled="disabled"
    :loading="loading"
  >
    <el-option
      v-for="make in makes"
      :key="make.value"
      :value="make.value"
      :label="make.label"
    >
    </el-option>
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "make-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [requestErrorMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    year: {
      type: Number | String | null | undefined,
      default: null,
      description: "Year",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
    placeholder: {
      type: String,
      default: "",
      description: "Placeholder",
    },
  },

  data() {
    const makeModel = this.value?.toLowerCase();
    return {
      makeModel,
      makes: [],
    };
  },

  setup() {},

  created() {
    this.getMakes();
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getMakes();
    }, 1000),

    async getMakes() {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {},
        };
        if (this.year) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              year: this.year,
            },
          };
        }

        await this.$store.dispatch("estimateVehicleMake/list", params);
        const makesArr = await this.$store.getters["estimateVehicleMake/list"];
        this.makes = [];
        let tempArr = [];
        makesArr.forEach((make) => {
          if (!tempArr.includes(make.name?.toLowerCase())) {
            tempArr.push(make.name?.toLowerCase());
            this.makes.push({
              value: make.name?.toLowerCase(),
              label: make.name,
            });
          }
        });
        if (!tempArr.includes(this.makeModel?.toLowerCase())) {
          this.makeModel = "";
          this.makeChanged("");
        }
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    makeChanged(make) {
      this.$emit("makeChanged", make);
    },
  },

  watch: {
    year: {
      handler: "getListDebounced",
      immediate: true,
    },
    value() {
      this.makeModel = this.value?.toLowerCase();
    },
  },
};
</script>
