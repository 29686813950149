<template>
  <div class="container-fluid">
    <vehicle-form
      :loading="loading"
      :vehicleData="vehicle"
      :formErrors="formErrors"
      @vehicleSubmitted="handleSubmit"
      @formChanged="handleFormChanged"
      />
<!--       @formChanged="() => (alertLeave = true)" -->
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultVehicle from "../defaultVehicle";
import VehicleForm from "../partials/VehicleForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    VehicleForm,
  },

  mixins: [alertLeave],

  data() {
    const vehicle = cloneDeep(defaultVehicle);
    return {
      vehicle: vehicle,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    handleFormChanged(newVehicleData) {
      this.alertLeave = true;
      console.log('Form changed:', newVehicleData);
    },
    async handleSubmit(vehicle) {
      this.loading = true;

      const vehicleData = cloneDeep(vehicle);
      delete vehicleData.id;

      try {
        await this.$store.dispatch("vehicles/add", vehicleData);
        this.$notify({
          type: "success",
          message: this.$t("VEHICLES.VEHICLE_ADDED"),
        });
        const vehicle = this.$store.getters["vehicles/vehicle"];
        this.$emit("onViewVehicle", vehicle, true);
        this.loading = false;
        console.log(vehicle,"add v component");
      } catch (error) {
        console.log(error,"erroorr in handle subl car")
        this.$notify({
          type: "danger",
          message: error ,  
     /*      this.$t("ERRORS.SOMETHING_WENT_WRONG"), */
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
